import React from 'react';
import Menu from '../components/Menu';
import Sidebar from '../components/Sidebar';
import TransactionsContent from '../contents/TransactionsContent';
import Footer from '../components/Footer';
import Auth from '../components/Auth';
import Widget from '../components/Widget';

export default function Transactions() {
	return (
		<>
			<div className="wrapper">
				{ <Sidebar/> }
				{ <Menu/> }
				{ <TransactionsContent/> }
				{ <Footer/> }
			</div>
			{ <Auth/> }
			{ <Widget/> }
		</>
	);
}
