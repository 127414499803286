import React from 'react';

export default function Menu() {
	return (
		<>
			<nav className="main-header navbar navbar-expand navbar-white navbar-light">
				<ul className="navbar-nav">
					<li className="nav-item">
						<a href="#" className="nav-link" data-widget="pushmenu" role="button">
							<i className="fa fa-bars" />
						</a>
					</li>
				</ul>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<a href="#" id="log-off" className="nav-link" title="Sair">
							<i className="fa fa-sign-out" />
						</a>
					</li>
				</ul>
			</nav>
		</>
	);
}
