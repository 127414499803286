import React from 'react';

export default function TransactionsContent() {
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-sm-6">
							<h1>Transações</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div id="paypal-datepicker" className="col-12">
							<div className="card card-primary">
								<div className="card-header">
									<h3 id="paypal-title" className="card-title">Todas as transações</h3>
									<a href="#" id="filter-btn" className="float-right">Filtrar</a>
								</div>
								<div className="card-body paypal-datepicker-body">
									<div className="row">
										<div className="col-sm-4">
											<div className="form-group paypal-datepicker-item">
												<label>Loja:</label>
												<select id="store" className="custom-select rounded-0" defaultValue={"UN"}>
													<option value={"FO"}>Flores Online</option>
													<option value={"IF"}>Isabela Flores</option>
													<option value={"UN"}>Uniflores</option>
												</select>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="form-group paypal-datepicker-item">
												<label>Data inicial:</label>
												<div className="input-group date" id="start-date" data-target-input="nearest">
													<input type="text" id="input-start-date" className="form-control datetimepicker-input" data-target="#start-date" defaultValue />
													<div className="input-group-append" data-target="#start-date" data-toggle="datetimepicker">
														<div className="input-group-text"><i className="fa fa-calendar" /></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="form-group paypal-datepicker-item">
												<label>Data final:</label>
												<div className="input-group date" id="final-date" data-target-input="nearest">
													<input type="text" id="input-final-date" className="form-control datetimepicker-input" data-target="#final-date" defaultValue />
													<div className="input-group-append" data-target="#final-date" data-toggle="datetimepicker">
														<div className="input-group-text"><i className="fa fa-calendar" /></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="transaction-data" className="col-12" style={{ display: 'none' }}>
							<div className="card">
								<div className="card-header">
									<h3 id="paypal-subtitle" className="card-title"> </h3>
								</div>
								<div id="paypal-content" className="card-body" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<input type="hidden" id="page-type" defaultValue="transactions" />
		</div>
	);
}
