import React from 'react';

export default function Footer() {
	return (
		<>
			<footer className="main-footer">
				<div className="float-right d-none d-sm-inline" />
				<strong>© Paypal System 2021.</strong> Todos os direitos reservados.
			</footer>
		</>
	);
}
