import React from 'react';

export default function Sidebar() {
	return (
		<>
			<aside className="main-sidebar sidebar-dark-primary elevation-4">
				<a href="/" className="brand-link">
					<img src="/dist/img/paypal-logo.png" alt="Paypal System" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
					<span className="brand-text font-weight-light">Paypal System</span>
				</a>
				<div className="sidebar">
					<div className="user-panel mt-3 pb-3 mb-3 d-flex">
						<div className="image">
							<img src="/dist/img/user.png" className="img-circle elevation-2" alt="User" />
						</div>
						<div className="info">
							<a href="#" className="d-block" />
						</div>
					</div>
					<nav className="mt-2">
						<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
							<li className="nav-item">
								<a href="/transactions" id="nav-link-transaction" className="nav-link">
									<p>
										Transações
									</p>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</aside>
		</>
	);
}
